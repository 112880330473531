<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>Correlation Analysis</h2>
    <div class="uk-grid">
      <div class="uk-width-2-3@m">
        <div class="uk-card uk-card-default uk-card-body uk-card-small">
          <correlation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Correlation from '@/components/charts/Correlation'

export default {
  components: {
    Correlation
  }
  
}
</script>